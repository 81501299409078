// source: ui/ui.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var flow_flow_pb = require('../flow/flow_pb.js');
goog.object.extend(proto, flow_flow_pb);
var ui_notifications_pb = require('../ui/notifications_pb.js');
goog.object.extend(proto, ui_notifications_pb);
var ui_status_pb = require('../ui/status_pb.js');
goog.object.extend(proto, ui_status_pb);
goog.exportSymbol('proto.ui.EventFilter', null, global);
goog.exportSymbol('proto.ui.EventFilter.FilterCase', null, global);
goog.exportSymbol('proto.ui.EventType', null, global);
goog.exportSymbol('proto.ui.Flows', null, global);
goog.exportSymbol('proto.ui.GetControlStreamRequest', null, global);
goog.exportSymbol('proto.ui.GetControlStreamResponse', null, global);
goog.exportSymbol('proto.ui.GetControlStreamResponse.EventCase', null, global);
goog.exportSymbol('proto.ui.GetControlStreamResponse.NamespaceStates', null, global);
goog.exportSymbol('proto.ui.GetEventsRequest', null, global);
goog.exportSymbol('proto.ui.GetEventsResponse', null, global);
goog.exportSymbol('proto.ui.GetEventsResponse.EventCase', null, global);
goog.exportSymbol('proto.ui.IPProtocol', null, global);
goog.exportSymbol('proto.ui.K8sNamespace', null, global);
goog.exportSymbol('proto.ui.K8sNamespaceState', null, global);
goog.exportSymbol('proto.ui.Service', null, global);
goog.exportSymbol('proto.ui.ServiceFilter', null, global);
goog.exportSymbol('proto.ui.ServiceLink', null, global);
goog.exportSymbol('proto.ui.ServiceLinkFilter', null, global);
goog.exportSymbol('proto.ui.ServiceLinkState', null, global);
goog.exportSymbol('proto.ui.ServiceState', null, global);
goog.exportSymbol('proto.ui.StateChange', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ui.GetEventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ui.GetEventsRequest.repeatedFields_, null);
};
goog.inherits(proto.ui.GetEventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ui.GetEventsRequest.displayName = 'proto.ui.GetEventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ui.GetEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.ui.GetEventsResponse.oneofGroups_);
};
goog.inherits(proto.ui.GetEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ui.GetEventsResponse.displayName = 'proto.ui.GetEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ui.Flows = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ui.Flows.repeatedFields_, null);
};
goog.inherits(proto.ui.Flows, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ui.Flows.displayName = 'proto.ui.Flows';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ui.EventFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.ui.EventFilter.oneofGroups_);
};
goog.inherits(proto.ui.EventFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ui.EventFilter.displayName = 'proto.ui.EventFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ui.K8sNamespace = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ui.K8sNamespace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ui.K8sNamespace.displayName = 'proto.ui.K8sNamespace';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ui.K8sNamespaceState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ui.K8sNamespaceState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ui.K8sNamespaceState.displayName = 'proto.ui.K8sNamespaceState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ui.Service = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ui.Service.repeatedFields_, null);
};
goog.inherits(proto.ui.Service, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ui.Service.displayName = 'proto.ui.Service';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ui.ServiceState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ui.ServiceState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ui.ServiceState.displayName = 'proto.ui.ServiceState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ui.ServiceFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ui.ServiceFilter.repeatedFields_, null);
};
goog.inherits(proto.ui.ServiceFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ui.ServiceFilter.displayName = 'proto.ui.ServiceFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ui.ServiceLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ui.ServiceLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ui.ServiceLink.displayName = 'proto.ui.ServiceLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ui.ServiceLinkState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ui.ServiceLinkState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ui.ServiceLinkState.displayName = 'proto.ui.ServiceLinkState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ui.ServiceLinkFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ui.ServiceLinkFilter.repeatedFields_, null);
};
goog.inherits(proto.ui.ServiceLinkFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ui.ServiceLinkFilter.displayName = 'proto.ui.ServiceLinkFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ui.GetControlStreamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ui.GetControlStreamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ui.GetControlStreamRequest.displayName = 'proto.ui.GetControlStreamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ui.GetControlStreamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.ui.GetControlStreamResponse.oneofGroups_);
};
goog.inherits(proto.ui.GetControlStreamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ui.GetControlStreamResponse.displayName = 'proto.ui.GetControlStreamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ui.GetControlStreamResponse.NamespaceStates = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ui.GetControlStreamResponse.NamespaceStates.repeatedFields_, null);
};
goog.inherits(proto.ui.GetControlStreamResponse.NamespaceStates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ui.GetControlStreamResponse.NamespaceStates.displayName = 'proto.ui.GetControlStreamResponse.NamespaceStates';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ui.GetEventsRequest.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ui.GetEventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ui.GetEventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ui.GetEventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.GetEventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventTypesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    blacklistList: jspb.Message.toObjectList(msg.getBlacklistList(),
    proto.ui.EventFilter.toObject, includeInstance),
    whitelistList: jspb.Message.toObjectList(msg.getWhitelistList(),
    proto.ui.EventFilter.toObject, includeInstance),
    since: (f = msg.getSince()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    statusRequest: (f = msg.getStatusRequest()) && ui_status_pb.GetStatusRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ui.GetEventsRequest}
 */
proto.ui.GetEventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ui.GetEventsRequest;
  return proto.ui.GetEventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ui.GetEventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ui.GetEventsRequest}
 */
proto.ui.GetEventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<!proto.ui.EventType>} */ (reader.readPackedEnum());
      msg.setEventTypesList(value);
      break;
    case 2:
      var value = new proto.ui.EventFilter;
      reader.readMessage(value,proto.ui.EventFilter.deserializeBinaryFromReader);
      msg.addBlacklist(value);
      break;
    case 3:
      var value = new proto.ui.EventFilter;
      reader.readMessage(value,proto.ui.EventFilter.deserializeBinaryFromReader);
      msg.addWhitelist(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSince(value);
      break;
    case 5:
      var value = new ui_status_pb.GetStatusRequest;
      reader.readMessage(value,ui_status_pb.GetStatusRequest.deserializeBinaryFromReader);
      msg.setStatusRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ui.GetEventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ui.GetEventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ui.GetEventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.GetEventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getBlacklistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.ui.EventFilter.serializeBinaryToWriter
    );
  }
  f = message.getWhitelistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.ui.EventFilter.serializeBinaryToWriter
    );
  }
  f = message.getSince();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatusRequest();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      ui_status_pb.GetStatusRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventType event_types = 1;
 * @return {!Array<!proto.ui.EventType>}
 */
proto.ui.GetEventsRequest.prototype.getEventTypesList = function() {
  return /** @type {!Array<!proto.ui.EventType>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.ui.EventType>} value
 * @return {!proto.ui.GetEventsRequest} returns this
 */
proto.ui.GetEventsRequest.prototype.setEventTypesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.ui.EventType} value
 * @param {number=} opt_index
 * @return {!proto.ui.GetEventsRequest} returns this
 */
proto.ui.GetEventsRequest.prototype.addEventTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ui.GetEventsRequest} returns this
 */
proto.ui.GetEventsRequest.prototype.clearEventTypesList = function() {
  return this.setEventTypesList([]);
};


/**
 * repeated EventFilter blacklist = 2;
 * @return {!Array<!proto.ui.EventFilter>}
 */
proto.ui.GetEventsRequest.prototype.getBlacklistList = function() {
  return /** @type{!Array<!proto.ui.EventFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ui.EventFilter, 2));
};


/**
 * @param {!Array<!proto.ui.EventFilter>} value
 * @return {!proto.ui.GetEventsRequest} returns this
*/
proto.ui.GetEventsRequest.prototype.setBlacklistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.ui.EventFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ui.EventFilter}
 */
proto.ui.GetEventsRequest.prototype.addBlacklist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.ui.EventFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ui.GetEventsRequest} returns this
 */
proto.ui.GetEventsRequest.prototype.clearBlacklistList = function() {
  return this.setBlacklistList([]);
};


/**
 * repeated EventFilter whitelist = 3;
 * @return {!Array<!proto.ui.EventFilter>}
 */
proto.ui.GetEventsRequest.prototype.getWhitelistList = function() {
  return /** @type{!Array<!proto.ui.EventFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ui.EventFilter, 3));
};


/**
 * @param {!Array<!proto.ui.EventFilter>} value
 * @return {!proto.ui.GetEventsRequest} returns this
*/
proto.ui.GetEventsRequest.prototype.setWhitelistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.ui.EventFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ui.EventFilter}
 */
proto.ui.GetEventsRequest.prototype.addWhitelist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.ui.EventFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ui.GetEventsRequest} returns this
 */
proto.ui.GetEventsRequest.prototype.clearWhitelistList = function() {
  return this.setWhitelistList([]);
};


/**
 * optional google.protobuf.Timestamp since = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ui.GetEventsRequest.prototype.getSince = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ui.GetEventsRequest} returns this
*/
proto.ui.GetEventsRequest.prototype.setSince = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ui.GetEventsRequest} returns this
 */
proto.ui.GetEventsRequest.prototype.clearSince = function() {
  return this.setSince(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ui.GetEventsRequest.prototype.hasSince = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional GetStatusRequest status_request = 5;
 * @return {?proto.ui.GetStatusRequest}
 */
proto.ui.GetEventsRequest.prototype.getStatusRequest = function() {
  return /** @type{?proto.ui.GetStatusRequest} */ (
    jspb.Message.getWrapperField(this, ui_status_pb.GetStatusRequest, 5));
};


/**
 * @param {?proto.ui.GetStatusRequest|undefined} value
 * @return {!proto.ui.GetEventsRequest} returns this
*/
proto.ui.GetEventsRequest.prototype.setStatusRequest = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ui.GetEventsRequest} returns this
 */
proto.ui.GetEventsRequest.prototype.clearStatusRequest = function() {
  return this.setStatusRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ui.GetEventsRequest.prototype.hasStatusRequest = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.ui.GetEventsResponse.oneofGroups_ = [[3,4,5,6,7,8]];

/**
 * @enum {number}
 */
proto.ui.GetEventsResponse.EventCase = {
  EVENT_NOT_SET: 0,
  FLOW: 3,
  K8S_NAMESPACE_STATE: 4,
  SERVICE_STATE: 5,
  SERVICE_LINK_STATE: 6,
  FLOWS: 7,
  NOTIFICATION: 8
};

/**
 * @return {proto.ui.GetEventsResponse.EventCase}
 */
proto.ui.GetEventsResponse.prototype.getEventCase = function() {
  return /** @type {proto.ui.GetEventsResponse.EventCase} */(jspb.Message.computeOneofCase(this, proto.ui.GetEventsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ui.GetEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ui.GetEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ui.GetEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.GetEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    flow: (f = msg.getFlow()) && flow_flow_pb.Flow.toObject(includeInstance, f),
    k8sNamespaceState: (f = msg.getK8sNamespaceState()) && proto.ui.K8sNamespaceState.toObject(includeInstance, f),
    serviceState: (f = msg.getServiceState()) && proto.ui.ServiceState.toObject(includeInstance, f),
    serviceLinkState: (f = msg.getServiceLinkState()) && proto.ui.ServiceLinkState.toObject(includeInstance, f),
    flows: (f = msg.getFlows()) && proto.ui.Flows.toObject(includeInstance, f),
    notification: (f = msg.getNotification()) && ui_notifications_pb.Notification.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ui.GetEventsResponse}
 */
proto.ui.GetEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ui.GetEventsResponse;
  return proto.ui.GetEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ui.GetEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ui.GetEventsResponse}
 */
proto.ui.GetEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNode(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 3:
      var value = new flow_flow_pb.Flow;
      reader.readMessage(value,flow_flow_pb.Flow.deserializeBinaryFromReader);
      msg.setFlow(value);
      break;
    case 4:
      var value = new proto.ui.K8sNamespaceState;
      reader.readMessage(value,proto.ui.K8sNamespaceState.deserializeBinaryFromReader);
      msg.setK8sNamespaceState(value);
      break;
    case 5:
      var value = new proto.ui.ServiceState;
      reader.readMessage(value,proto.ui.ServiceState.deserializeBinaryFromReader);
      msg.setServiceState(value);
      break;
    case 6:
      var value = new proto.ui.ServiceLinkState;
      reader.readMessage(value,proto.ui.ServiceLinkState.deserializeBinaryFromReader);
      msg.setServiceLinkState(value);
      break;
    case 7:
      var value = new proto.ui.Flows;
      reader.readMessage(value,proto.ui.Flows.deserializeBinaryFromReader);
      msg.setFlows(value);
      break;
    case 8:
      var value = new ui_notifications_pb.Notification;
      reader.readMessage(value,ui_notifications_pb.Notification.deserializeBinaryFromReader);
      msg.setNotification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ui.GetEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ui.GetEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ui.GetEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.GetEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFlow();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      flow_flow_pb.Flow.serializeBinaryToWriter
    );
  }
  f = message.getK8sNamespaceState();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.ui.K8sNamespaceState.serializeBinaryToWriter
    );
  }
  f = message.getServiceState();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.ui.ServiceState.serializeBinaryToWriter
    );
  }
  f = message.getServiceLinkState();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.ui.ServiceLinkState.serializeBinaryToWriter
    );
  }
  f = message.getFlows();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.ui.Flows.serializeBinaryToWriter
    );
  }
  f = message.getNotification();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      ui_notifications_pb.Notification.serializeBinaryToWriter
    );
  }
};


/**
 * optional string node = 1;
 * @return {string}
 */
proto.ui.GetEventsResponse.prototype.getNode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ui.GetEventsResponse} returns this
 */
proto.ui.GetEventsResponse.prototype.setNode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ui.GetEventsResponse.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ui.GetEventsResponse} returns this
*/
proto.ui.GetEventsResponse.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ui.GetEventsResponse} returns this
 */
proto.ui.GetEventsResponse.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ui.GetEventsResponse.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional flow.Flow flow = 3;
 * @return {?proto.flow.Flow}
 */
proto.ui.GetEventsResponse.prototype.getFlow = function() {
  return /** @type{?proto.flow.Flow} */ (
    jspb.Message.getWrapperField(this, flow_flow_pb.Flow, 3));
};


/**
 * @param {?proto.flow.Flow|undefined} value
 * @return {!proto.ui.GetEventsResponse} returns this
*/
proto.ui.GetEventsResponse.prototype.setFlow = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.ui.GetEventsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ui.GetEventsResponse} returns this
 */
proto.ui.GetEventsResponse.prototype.clearFlow = function() {
  return this.setFlow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ui.GetEventsResponse.prototype.hasFlow = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional K8sNamespaceState k8s_namespace_state = 4;
 * @return {?proto.ui.K8sNamespaceState}
 */
proto.ui.GetEventsResponse.prototype.getK8sNamespaceState = function() {
  return /** @type{?proto.ui.K8sNamespaceState} */ (
    jspb.Message.getWrapperField(this, proto.ui.K8sNamespaceState, 4));
};


/**
 * @param {?proto.ui.K8sNamespaceState|undefined} value
 * @return {!proto.ui.GetEventsResponse} returns this
*/
proto.ui.GetEventsResponse.prototype.setK8sNamespaceState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.ui.GetEventsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ui.GetEventsResponse} returns this
 */
proto.ui.GetEventsResponse.prototype.clearK8sNamespaceState = function() {
  return this.setK8sNamespaceState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ui.GetEventsResponse.prototype.hasK8sNamespaceState = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ServiceState service_state = 5;
 * @return {?proto.ui.ServiceState}
 */
proto.ui.GetEventsResponse.prototype.getServiceState = function() {
  return /** @type{?proto.ui.ServiceState} */ (
    jspb.Message.getWrapperField(this, proto.ui.ServiceState, 5));
};


/**
 * @param {?proto.ui.ServiceState|undefined} value
 * @return {!proto.ui.GetEventsResponse} returns this
*/
proto.ui.GetEventsResponse.prototype.setServiceState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.ui.GetEventsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ui.GetEventsResponse} returns this
 */
proto.ui.GetEventsResponse.prototype.clearServiceState = function() {
  return this.setServiceState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ui.GetEventsResponse.prototype.hasServiceState = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ServiceLinkState service_link_state = 6;
 * @return {?proto.ui.ServiceLinkState}
 */
proto.ui.GetEventsResponse.prototype.getServiceLinkState = function() {
  return /** @type{?proto.ui.ServiceLinkState} */ (
    jspb.Message.getWrapperField(this, proto.ui.ServiceLinkState, 6));
};


/**
 * @param {?proto.ui.ServiceLinkState|undefined} value
 * @return {!proto.ui.GetEventsResponse} returns this
*/
proto.ui.GetEventsResponse.prototype.setServiceLinkState = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.ui.GetEventsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ui.GetEventsResponse} returns this
 */
proto.ui.GetEventsResponse.prototype.clearServiceLinkState = function() {
  return this.setServiceLinkState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ui.GetEventsResponse.prototype.hasServiceLinkState = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Flows flows = 7;
 * @return {?proto.ui.Flows}
 */
proto.ui.GetEventsResponse.prototype.getFlows = function() {
  return /** @type{?proto.ui.Flows} */ (
    jspb.Message.getWrapperField(this, proto.ui.Flows, 7));
};


/**
 * @param {?proto.ui.Flows|undefined} value
 * @return {!proto.ui.GetEventsResponse} returns this
*/
proto.ui.GetEventsResponse.prototype.setFlows = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.ui.GetEventsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ui.GetEventsResponse} returns this
 */
proto.ui.GetEventsResponse.prototype.clearFlows = function() {
  return this.setFlows(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ui.GetEventsResponse.prototype.hasFlows = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Notification notification = 8;
 * @return {?proto.ui.Notification}
 */
proto.ui.GetEventsResponse.prototype.getNotification = function() {
  return /** @type{?proto.ui.Notification} */ (
    jspb.Message.getWrapperField(this, ui_notifications_pb.Notification, 8));
};


/**
 * @param {?proto.ui.Notification|undefined} value
 * @return {!proto.ui.GetEventsResponse} returns this
*/
proto.ui.GetEventsResponse.prototype.setNotification = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.ui.GetEventsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ui.GetEventsResponse} returns this
 */
proto.ui.GetEventsResponse.prototype.clearNotification = function() {
  return this.setNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ui.GetEventsResponse.prototype.hasNotification = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ui.Flows.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ui.Flows.prototype.toObject = function(opt_includeInstance) {
  return proto.ui.Flows.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ui.Flows} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.Flows.toObject = function(includeInstance, msg) {
  var f, obj = {
    flowsList: jspb.Message.toObjectList(msg.getFlowsList(),
    flow_flow_pb.Flow.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ui.Flows}
 */
proto.ui.Flows.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ui.Flows;
  return proto.ui.Flows.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ui.Flows} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ui.Flows}
 */
proto.ui.Flows.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new flow_flow_pb.Flow;
      reader.readMessage(value,flow_flow_pb.Flow.deserializeBinaryFromReader);
      msg.addFlows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ui.Flows.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ui.Flows.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ui.Flows} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.Flows.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      flow_flow_pb.Flow.serializeBinaryToWriter
    );
  }
};


/**
 * repeated flow.Flow flows = 1;
 * @return {!Array<!proto.flow.Flow>}
 */
proto.ui.Flows.prototype.getFlowsList = function() {
  return /** @type{!Array<!proto.flow.Flow>} */ (
    jspb.Message.getRepeatedWrapperField(this, flow_flow_pb.Flow, 1));
};


/**
 * @param {!Array<!proto.flow.Flow>} value
 * @return {!proto.ui.Flows} returns this
*/
proto.ui.Flows.prototype.setFlowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.flow.Flow=} opt_value
 * @param {number=} opt_index
 * @return {!proto.flow.Flow}
 */
proto.ui.Flows.prototype.addFlows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.flow.Flow, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ui.Flows} returns this
 */
proto.ui.Flows.prototype.clearFlowsList = function() {
  return this.setFlowsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.ui.EventFilter.oneofGroups_ = [[2,3,4]];

/**
 * @enum {number}
 */
proto.ui.EventFilter.FilterCase = {
  FILTER_NOT_SET: 0,
  FLOW_FILTER: 2,
  SERVICE_FILTER: 3,
  SERVICE_LINK_FILTER: 4
};

/**
 * @return {proto.ui.EventFilter.FilterCase}
 */
proto.ui.EventFilter.prototype.getFilterCase = function() {
  return /** @type {proto.ui.EventFilter.FilterCase} */(jspb.Message.computeOneofCase(this, proto.ui.EventFilter.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ui.EventFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.ui.EventFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ui.EventFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.EventFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    flowFilter: (f = msg.getFlowFilter()) && flow_flow_pb.FlowFilter.toObject(includeInstance, f),
    serviceFilter: (f = msg.getServiceFilter()) && proto.ui.ServiceFilter.toObject(includeInstance, f),
    serviceLinkFilter: (f = msg.getServiceLinkFilter()) && proto.ui.ServiceLinkFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ui.EventFilter}
 */
proto.ui.EventFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ui.EventFilter;
  return proto.ui.EventFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ui.EventFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ui.EventFilter}
 */
proto.ui.EventFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new flow_flow_pb.FlowFilter;
      reader.readMessage(value,flow_flow_pb.FlowFilter.deserializeBinaryFromReader);
      msg.setFlowFilter(value);
      break;
    case 3:
      var value = new proto.ui.ServiceFilter;
      reader.readMessage(value,proto.ui.ServiceFilter.deserializeBinaryFromReader);
      msg.setServiceFilter(value);
      break;
    case 4:
      var value = new proto.ui.ServiceLinkFilter;
      reader.readMessage(value,proto.ui.ServiceLinkFilter.deserializeBinaryFromReader);
      msg.setServiceLinkFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ui.EventFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ui.EventFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ui.EventFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.EventFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlowFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      flow_flow_pb.FlowFilter.serializeBinaryToWriter
    );
  }
  f = message.getServiceFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ui.ServiceFilter.serializeBinaryToWriter
    );
  }
  f = message.getServiceLinkFilter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.ui.ServiceLinkFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional flow.FlowFilter flow_filter = 2;
 * @return {?proto.flow.FlowFilter}
 */
proto.ui.EventFilter.prototype.getFlowFilter = function() {
  return /** @type{?proto.flow.FlowFilter} */ (
    jspb.Message.getWrapperField(this, flow_flow_pb.FlowFilter, 2));
};


/**
 * @param {?proto.flow.FlowFilter|undefined} value
 * @return {!proto.ui.EventFilter} returns this
*/
proto.ui.EventFilter.prototype.setFlowFilter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.ui.EventFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ui.EventFilter} returns this
 */
proto.ui.EventFilter.prototype.clearFlowFilter = function() {
  return this.setFlowFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ui.EventFilter.prototype.hasFlowFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ServiceFilter service_filter = 3;
 * @return {?proto.ui.ServiceFilter}
 */
proto.ui.EventFilter.prototype.getServiceFilter = function() {
  return /** @type{?proto.ui.ServiceFilter} */ (
    jspb.Message.getWrapperField(this, proto.ui.ServiceFilter, 3));
};


/**
 * @param {?proto.ui.ServiceFilter|undefined} value
 * @return {!proto.ui.EventFilter} returns this
*/
proto.ui.EventFilter.prototype.setServiceFilter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.ui.EventFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ui.EventFilter} returns this
 */
proto.ui.EventFilter.prototype.clearServiceFilter = function() {
  return this.setServiceFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ui.EventFilter.prototype.hasServiceFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ServiceLinkFilter service_link_filter = 4;
 * @return {?proto.ui.ServiceLinkFilter}
 */
proto.ui.EventFilter.prototype.getServiceLinkFilter = function() {
  return /** @type{?proto.ui.ServiceLinkFilter} */ (
    jspb.Message.getWrapperField(this, proto.ui.ServiceLinkFilter, 4));
};


/**
 * @param {?proto.ui.ServiceLinkFilter|undefined} value
 * @return {!proto.ui.EventFilter} returns this
*/
proto.ui.EventFilter.prototype.setServiceLinkFilter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.ui.EventFilter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ui.EventFilter} returns this
 */
proto.ui.EventFilter.prototype.clearServiceLinkFilter = function() {
  return this.setServiceLinkFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ui.EventFilter.prototype.hasServiceLinkFilter = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ui.K8sNamespace.prototype.toObject = function(opt_includeInstance) {
  return proto.ui.K8sNamespace.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ui.K8sNamespace} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.K8sNamespace.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    creationTimestamp: (f = msg.getCreationTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ui.K8sNamespace}
 */
proto.ui.K8sNamespace.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ui.K8sNamespace;
  return proto.ui.K8sNamespace.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ui.K8sNamespace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ui.K8sNamespace}
 */
proto.ui.K8sNamespace.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ui.K8sNamespace.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ui.K8sNamespace.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ui.K8sNamespace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.K8sNamespace.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreationTimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.ui.K8sNamespace.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ui.K8sNamespace} returns this
 */
proto.ui.K8sNamespace.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.ui.K8sNamespace.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ui.K8sNamespace} returns this
 */
proto.ui.K8sNamespace.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp creation_timestamp = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ui.K8sNamespace.prototype.getCreationTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ui.K8sNamespace} returns this
*/
proto.ui.K8sNamespace.prototype.setCreationTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ui.K8sNamespace} returns this
 */
proto.ui.K8sNamespace.prototype.clearCreationTimestamp = function() {
  return this.setCreationTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ui.K8sNamespace.prototype.hasCreationTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ui.K8sNamespaceState.prototype.toObject = function(opt_includeInstance) {
  return proto.ui.K8sNamespaceState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ui.K8sNamespaceState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.K8sNamespaceState.toObject = function(includeInstance, msg) {
  var f, obj = {
    namespace: (f = msg.getNamespace()) && proto.ui.K8sNamespace.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ui.K8sNamespaceState}
 */
proto.ui.K8sNamespaceState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ui.K8sNamespaceState;
  return proto.ui.K8sNamespaceState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ui.K8sNamespaceState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ui.K8sNamespaceState}
 */
proto.ui.K8sNamespaceState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ui.K8sNamespace;
      reader.readMessage(value,proto.ui.K8sNamespace.deserializeBinaryFromReader);
      msg.setNamespace(value);
      break;
    case 2:
      var value = /** @type {!proto.ui.StateChange} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ui.K8sNamespaceState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ui.K8sNamespaceState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ui.K8sNamespaceState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.K8sNamespaceState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNamespace();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ui.K8sNamespace.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional K8sNamespace namespace = 1;
 * @return {?proto.ui.K8sNamespace}
 */
proto.ui.K8sNamespaceState.prototype.getNamespace = function() {
  return /** @type{?proto.ui.K8sNamespace} */ (
    jspb.Message.getWrapperField(this, proto.ui.K8sNamespace, 1));
};


/**
 * @param {?proto.ui.K8sNamespace|undefined} value
 * @return {!proto.ui.K8sNamespaceState} returns this
*/
proto.ui.K8sNamespaceState.prototype.setNamespace = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ui.K8sNamespaceState} returns this
 */
proto.ui.K8sNamespaceState.prototype.clearNamespace = function() {
  return this.setNamespace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ui.K8sNamespaceState.prototype.hasNamespace = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StateChange type = 2;
 * @return {!proto.ui.StateChange}
 */
proto.ui.K8sNamespaceState.prototype.getType = function() {
  return /** @type {!proto.ui.StateChange} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ui.StateChange} value
 * @return {!proto.ui.K8sNamespaceState} returns this
 */
proto.ui.K8sNamespaceState.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ui.Service.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ui.Service.prototype.toObject = function(opt_includeInstance) {
  return proto.ui.Service.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ui.Service} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.Service.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    namespace: jspb.Message.getFieldWithDefault(msg, 3, ""),
    labelsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    dnsNamesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    egressPolicyEnforced: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    ingressPolicyEnforced: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    visibilityPolicyStatus: jspb.Message.getFieldWithDefault(msg, 8, ""),
    creationTimestamp: (f = msg.getCreationTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ui.Service}
 */
proto.ui.Service.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ui.Service;
  return proto.ui.Service.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ui.Service} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ui.Service}
 */
proto.ui.Service.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamespace(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabels(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addDnsNames(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEgressPolicyEnforced(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIngressPolicyEnforced(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setVisibilityPolicyStatus(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ui.Service.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ui.Service.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ui.Service} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.Service.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNamespace();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getDnsNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getEgressPolicyEnforced();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIngressPolicyEnforced();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getVisibilityPolicyStatus();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreationTimestamp();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.ui.Service.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ui.Service} returns this
 */
proto.ui.Service.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.ui.Service.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ui.Service} returns this
 */
proto.ui.Service.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string namespace = 3;
 * @return {string}
 */
proto.ui.Service.prototype.getNamespace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ui.Service} returns this
 */
proto.ui.Service.prototype.setNamespace = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string labels = 4;
 * @return {!Array<string>}
 */
proto.ui.Service.prototype.getLabelsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ui.Service} returns this
 */
proto.ui.Service.prototype.setLabelsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ui.Service} returns this
 */
proto.ui.Service.prototype.addLabels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ui.Service} returns this
 */
proto.ui.Service.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * repeated string dns_names = 5;
 * @return {!Array<string>}
 */
proto.ui.Service.prototype.getDnsNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ui.Service} returns this
 */
proto.ui.Service.prototype.setDnsNamesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ui.Service} returns this
 */
proto.ui.Service.prototype.addDnsNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ui.Service} returns this
 */
proto.ui.Service.prototype.clearDnsNamesList = function() {
  return this.setDnsNamesList([]);
};


/**
 * optional bool egress_policy_enforced = 6;
 * @return {boolean}
 */
proto.ui.Service.prototype.getEgressPolicyEnforced = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ui.Service} returns this
 */
proto.ui.Service.prototype.setEgressPolicyEnforced = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool ingress_policy_enforced = 7;
 * @return {boolean}
 */
proto.ui.Service.prototype.getIngressPolicyEnforced = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ui.Service} returns this
 */
proto.ui.Service.prototype.setIngressPolicyEnforced = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string visibility_policy_status = 8;
 * @return {string}
 */
proto.ui.Service.prototype.getVisibilityPolicyStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.ui.Service} returns this
 */
proto.ui.Service.prototype.setVisibilityPolicyStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp creation_timestamp = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ui.Service.prototype.getCreationTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ui.Service} returns this
*/
proto.ui.Service.prototype.setCreationTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ui.Service} returns this
 */
proto.ui.Service.prototype.clearCreationTimestamp = function() {
  return this.setCreationTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ui.Service.prototype.hasCreationTimestamp = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ui.ServiceState.prototype.toObject = function(opt_includeInstance) {
  return proto.ui.ServiceState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ui.ServiceState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.ServiceState.toObject = function(includeInstance, msg) {
  var f, obj = {
    service: (f = msg.getService()) && proto.ui.Service.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ui.ServiceState}
 */
proto.ui.ServiceState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ui.ServiceState;
  return proto.ui.ServiceState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ui.ServiceState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ui.ServiceState}
 */
proto.ui.ServiceState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ui.Service;
      reader.readMessage(value,proto.ui.Service.deserializeBinaryFromReader);
      msg.setService(value);
      break;
    case 2:
      var value = /** @type {!proto.ui.StateChange} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ui.ServiceState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ui.ServiceState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ui.ServiceState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.ServiceState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getService();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ui.Service.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional Service service = 1;
 * @return {?proto.ui.Service}
 */
proto.ui.ServiceState.prototype.getService = function() {
  return /** @type{?proto.ui.Service} */ (
    jspb.Message.getWrapperField(this, proto.ui.Service, 1));
};


/**
 * @param {?proto.ui.Service|undefined} value
 * @return {!proto.ui.ServiceState} returns this
*/
proto.ui.ServiceState.prototype.setService = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ui.ServiceState} returns this
 */
proto.ui.ServiceState.prototype.clearService = function() {
  return this.setService(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ui.ServiceState.prototype.hasService = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StateChange type = 2;
 * @return {!proto.ui.StateChange}
 */
proto.ui.ServiceState.prototype.getType = function() {
  return /** @type {!proto.ui.StateChange} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ui.StateChange} value
 * @return {!proto.ui.ServiceState} returns this
 */
proto.ui.ServiceState.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ui.ServiceFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ui.ServiceFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.ui.ServiceFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ui.ServiceFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.ServiceFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    namespaceList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ui.ServiceFilter}
 */
proto.ui.ServiceFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ui.ServiceFilter;
  return proto.ui.ServiceFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ui.ServiceFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ui.ServiceFilter}
 */
proto.ui.ServiceFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addNamespace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ui.ServiceFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ui.ServiceFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ui.ServiceFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.ServiceFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNamespaceList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string namespace = 1;
 * @return {!Array<string>}
 */
proto.ui.ServiceFilter.prototype.getNamespaceList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ui.ServiceFilter} returns this
 */
proto.ui.ServiceFilter.prototype.setNamespaceList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ui.ServiceFilter} returns this
 */
proto.ui.ServiceFilter.prototype.addNamespace = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ui.ServiceFilter} returns this
 */
proto.ui.ServiceFilter.prototype.clearNamespaceList = function() {
  return this.setNamespaceList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ui.ServiceLink.prototype.toObject = function(opt_includeInstance) {
  return proto.ui.ServiceLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ui.ServiceLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.ServiceLink.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sourceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    destinationId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    destinationPort: jspb.Message.getFieldWithDefault(msg, 4, 0),
    ipProtocol: jspb.Message.getFieldWithDefault(msg, 5, 0),
    verdict: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ui.ServiceLink}
 */
proto.ui.ServiceLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ui.ServiceLink;
  return proto.ui.ServiceLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ui.ServiceLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ui.ServiceLink}
 */
proto.ui.ServiceLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDestinationPort(value);
      break;
    case 5:
      var value = /** @type {!proto.ui.IPProtocol} */ (reader.readEnum());
      msg.setIpProtocol(value);
      break;
    case 6:
      var value = /** @type {!proto.flow.Verdict} */ (reader.readEnum());
      msg.setVerdict(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ui.ServiceLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ui.ServiceLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ui.ServiceLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.ServiceLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSourceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDestinationId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDestinationPort();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getIpProtocol();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getVerdict();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.ui.ServiceLink.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ui.ServiceLink} returns this
 */
proto.ui.ServiceLink.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string source_id = 2;
 * @return {string}
 */
proto.ui.ServiceLink.prototype.getSourceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ui.ServiceLink} returns this
 */
proto.ui.ServiceLink.prototype.setSourceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string destination_id = 3;
 * @return {string}
 */
proto.ui.ServiceLink.prototype.getDestinationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ui.ServiceLink} returns this
 */
proto.ui.ServiceLink.prototype.setDestinationId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 destination_port = 4;
 * @return {number}
 */
proto.ui.ServiceLink.prototype.getDestinationPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ui.ServiceLink} returns this
 */
proto.ui.ServiceLink.prototype.setDestinationPort = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional IPProtocol ip_protocol = 5;
 * @return {!proto.ui.IPProtocol}
 */
proto.ui.ServiceLink.prototype.getIpProtocol = function() {
  return /** @type {!proto.ui.IPProtocol} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.ui.IPProtocol} value
 * @return {!proto.ui.ServiceLink} returns this
 */
proto.ui.ServiceLink.prototype.setIpProtocol = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional flow.Verdict verdict = 6;
 * @return {!proto.flow.Verdict}
 */
proto.ui.ServiceLink.prototype.getVerdict = function() {
  return /** @type {!proto.flow.Verdict} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.flow.Verdict} value
 * @return {!proto.ui.ServiceLink} returns this
 */
proto.ui.ServiceLink.prototype.setVerdict = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ui.ServiceLinkState.prototype.toObject = function(opt_includeInstance) {
  return proto.ui.ServiceLinkState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ui.ServiceLinkState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.ServiceLinkState.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceLink: (f = msg.getServiceLink()) && proto.ui.ServiceLink.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ui.ServiceLinkState}
 */
proto.ui.ServiceLinkState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ui.ServiceLinkState;
  return proto.ui.ServiceLinkState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ui.ServiceLinkState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ui.ServiceLinkState}
 */
proto.ui.ServiceLinkState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ui.ServiceLink;
      reader.readMessage(value,proto.ui.ServiceLink.deserializeBinaryFromReader);
      msg.setServiceLink(value);
      break;
    case 2:
      var value = /** @type {!proto.ui.StateChange} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ui.ServiceLinkState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ui.ServiceLinkState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ui.ServiceLinkState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.ServiceLinkState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceLink();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ui.ServiceLink.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional ServiceLink service_link = 1;
 * @return {?proto.ui.ServiceLink}
 */
proto.ui.ServiceLinkState.prototype.getServiceLink = function() {
  return /** @type{?proto.ui.ServiceLink} */ (
    jspb.Message.getWrapperField(this, proto.ui.ServiceLink, 1));
};


/**
 * @param {?proto.ui.ServiceLink|undefined} value
 * @return {!proto.ui.ServiceLinkState} returns this
*/
proto.ui.ServiceLinkState.prototype.setServiceLink = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ui.ServiceLinkState} returns this
 */
proto.ui.ServiceLinkState.prototype.clearServiceLink = function() {
  return this.setServiceLink(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ui.ServiceLinkState.prototype.hasServiceLink = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StateChange type = 2;
 * @return {!proto.ui.StateChange}
 */
proto.ui.ServiceLinkState.prototype.getType = function() {
  return /** @type {!proto.ui.StateChange} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ui.StateChange} value
 * @return {!proto.ui.ServiceLinkState} returns this
 */
proto.ui.ServiceLinkState.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ui.ServiceLinkFilter.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ui.ServiceLinkFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.ui.ServiceLinkFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ui.ServiceLinkFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.ServiceLinkFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourceList: jspb.Message.toObjectList(msg.getSourceList(),
    proto.ui.ServiceFilter.toObject, includeInstance),
    destinationList: jspb.Message.toObjectList(msg.getDestinationList(),
    proto.ui.ServiceFilter.toObject, includeInstance),
    destinationPortList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    verdictList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ui.ServiceLinkFilter}
 */
proto.ui.ServiceLinkFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ui.ServiceLinkFilter;
  return proto.ui.ServiceLinkFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ui.ServiceLinkFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ui.ServiceLinkFilter}
 */
proto.ui.ServiceLinkFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ui.ServiceFilter;
      reader.readMessage(value,proto.ui.ServiceFilter.deserializeBinaryFromReader);
      msg.addSource(value);
      break;
    case 2:
      var value = new proto.ui.ServiceFilter;
      reader.readMessage(value,proto.ui.ServiceFilter.deserializeBinaryFromReader);
      msg.addDestination(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addDestinationPort(value);
      break;
    case 4:
      var value = /** @type {!Array<!proto.flow.Verdict>} */ (reader.readPackedEnum());
      msg.setVerdictList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ui.ServiceLinkFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ui.ServiceLinkFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ui.ServiceLinkFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.ServiceLinkFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ui.ServiceFilter.serializeBinaryToWriter
    );
  }
  f = message.getDestinationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.ui.ServiceFilter.serializeBinaryToWriter
    );
  }
  f = message.getDestinationPortList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getVerdictList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * repeated ServiceFilter source = 1;
 * @return {!Array<!proto.ui.ServiceFilter>}
 */
proto.ui.ServiceLinkFilter.prototype.getSourceList = function() {
  return /** @type{!Array<!proto.ui.ServiceFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ui.ServiceFilter, 1));
};


/**
 * @param {!Array<!proto.ui.ServiceFilter>} value
 * @return {!proto.ui.ServiceLinkFilter} returns this
*/
proto.ui.ServiceLinkFilter.prototype.setSourceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ui.ServiceFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ui.ServiceFilter}
 */
proto.ui.ServiceLinkFilter.prototype.addSource = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ui.ServiceFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ui.ServiceLinkFilter} returns this
 */
proto.ui.ServiceLinkFilter.prototype.clearSourceList = function() {
  return this.setSourceList([]);
};


/**
 * repeated ServiceFilter destination = 2;
 * @return {!Array<!proto.ui.ServiceFilter>}
 */
proto.ui.ServiceLinkFilter.prototype.getDestinationList = function() {
  return /** @type{!Array<!proto.ui.ServiceFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ui.ServiceFilter, 2));
};


/**
 * @param {!Array<!proto.ui.ServiceFilter>} value
 * @return {!proto.ui.ServiceLinkFilter} returns this
*/
proto.ui.ServiceLinkFilter.prototype.setDestinationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.ui.ServiceFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ui.ServiceFilter}
 */
proto.ui.ServiceLinkFilter.prototype.addDestination = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.ui.ServiceFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ui.ServiceLinkFilter} returns this
 */
proto.ui.ServiceLinkFilter.prototype.clearDestinationList = function() {
  return this.setDestinationList([]);
};


/**
 * repeated string destination_port = 3;
 * @return {!Array<string>}
 */
proto.ui.ServiceLinkFilter.prototype.getDestinationPortList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ui.ServiceLinkFilter} returns this
 */
proto.ui.ServiceLinkFilter.prototype.setDestinationPortList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ui.ServiceLinkFilter} returns this
 */
proto.ui.ServiceLinkFilter.prototype.addDestinationPort = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ui.ServiceLinkFilter} returns this
 */
proto.ui.ServiceLinkFilter.prototype.clearDestinationPortList = function() {
  return this.setDestinationPortList([]);
};


/**
 * repeated flow.Verdict verdict = 4;
 * @return {!Array<!proto.flow.Verdict>}
 */
proto.ui.ServiceLinkFilter.prototype.getVerdictList = function() {
  return /** @type {!Array<!proto.flow.Verdict>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.flow.Verdict>} value
 * @return {!proto.ui.ServiceLinkFilter} returns this
 */
proto.ui.ServiceLinkFilter.prototype.setVerdictList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.flow.Verdict} value
 * @param {number=} opt_index
 * @return {!proto.ui.ServiceLinkFilter} returns this
 */
proto.ui.ServiceLinkFilter.prototype.addVerdict = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ui.ServiceLinkFilter} returns this
 */
proto.ui.ServiceLinkFilter.prototype.clearVerdictList = function() {
  return this.setVerdictList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ui.GetControlStreamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ui.GetControlStreamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ui.GetControlStreamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.GetControlStreamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ui.GetControlStreamRequest}
 */
proto.ui.GetControlStreamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ui.GetControlStreamRequest;
  return proto.ui.GetControlStreamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ui.GetControlStreamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ui.GetControlStreamRequest}
 */
proto.ui.GetControlStreamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ui.GetControlStreamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ui.GetControlStreamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ui.GetControlStreamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.GetControlStreamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.ui.GetControlStreamResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.ui.GetControlStreamResponse.EventCase = {
  EVENT_NOT_SET: 0,
  NAMESPACES: 1,
  NOTIFICATION: 2
};

/**
 * @return {proto.ui.GetControlStreamResponse.EventCase}
 */
proto.ui.GetControlStreamResponse.prototype.getEventCase = function() {
  return /** @type {proto.ui.GetControlStreamResponse.EventCase} */(jspb.Message.computeOneofCase(this, proto.ui.GetControlStreamResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ui.GetControlStreamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ui.GetControlStreamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ui.GetControlStreamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.GetControlStreamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    namespaces: (f = msg.getNamespaces()) && proto.ui.GetControlStreamResponse.NamespaceStates.toObject(includeInstance, f),
    notification: (f = msg.getNotification()) && ui_notifications_pb.Notification.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ui.GetControlStreamResponse}
 */
proto.ui.GetControlStreamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ui.GetControlStreamResponse;
  return proto.ui.GetControlStreamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ui.GetControlStreamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ui.GetControlStreamResponse}
 */
proto.ui.GetControlStreamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ui.GetControlStreamResponse.NamespaceStates;
      reader.readMessage(value,proto.ui.GetControlStreamResponse.NamespaceStates.deserializeBinaryFromReader);
      msg.setNamespaces(value);
      break;
    case 2:
      var value = new ui_notifications_pb.Notification;
      reader.readMessage(value,ui_notifications_pb.Notification.deserializeBinaryFromReader);
      msg.setNotification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ui.GetControlStreamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ui.GetControlStreamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ui.GetControlStreamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.GetControlStreamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNamespaces();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ui.GetControlStreamResponse.NamespaceStates.serializeBinaryToWriter
    );
  }
  f = message.getNotification();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      ui_notifications_pb.Notification.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ui.GetControlStreamResponse.NamespaceStates.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ui.GetControlStreamResponse.NamespaceStates.prototype.toObject = function(opt_includeInstance) {
  return proto.ui.GetControlStreamResponse.NamespaceStates.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ui.GetControlStreamResponse.NamespaceStates} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.GetControlStreamResponse.NamespaceStates.toObject = function(includeInstance, msg) {
  var f, obj = {
    namespacesList: jspb.Message.toObjectList(msg.getNamespacesList(),
    proto.ui.K8sNamespaceState.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ui.GetControlStreamResponse.NamespaceStates}
 */
proto.ui.GetControlStreamResponse.NamespaceStates.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ui.GetControlStreamResponse.NamespaceStates;
  return proto.ui.GetControlStreamResponse.NamespaceStates.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ui.GetControlStreamResponse.NamespaceStates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ui.GetControlStreamResponse.NamespaceStates}
 */
proto.ui.GetControlStreamResponse.NamespaceStates.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ui.K8sNamespaceState;
      reader.readMessage(value,proto.ui.K8sNamespaceState.deserializeBinaryFromReader);
      msg.addNamespaces(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ui.GetControlStreamResponse.NamespaceStates.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ui.GetControlStreamResponse.NamespaceStates.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ui.GetControlStreamResponse.NamespaceStates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ui.GetControlStreamResponse.NamespaceStates.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNamespacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ui.K8sNamespaceState.serializeBinaryToWriter
    );
  }
};


/**
 * repeated K8sNamespaceState namespaces = 1;
 * @return {!Array<!proto.ui.K8sNamespaceState>}
 */
proto.ui.GetControlStreamResponse.NamespaceStates.prototype.getNamespacesList = function() {
  return /** @type{!Array<!proto.ui.K8sNamespaceState>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ui.K8sNamespaceState, 1));
};


/**
 * @param {!Array<!proto.ui.K8sNamespaceState>} value
 * @return {!proto.ui.GetControlStreamResponse.NamespaceStates} returns this
*/
proto.ui.GetControlStreamResponse.NamespaceStates.prototype.setNamespacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ui.K8sNamespaceState=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ui.K8sNamespaceState}
 */
proto.ui.GetControlStreamResponse.NamespaceStates.prototype.addNamespaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ui.K8sNamespaceState, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ui.GetControlStreamResponse.NamespaceStates} returns this
 */
proto.ui.GetControlStreamResponse.NamespaceStates.prototype.clearNamespacesList = function() {
  return this.setNamespacesList([]);
};


/**
 * optional NamespaceStates namespaces = 1;
 * @return {?proto.ui.GetControlStreamResponse.NamespaceStates}
 */
proto.ui.GetControlStreamResponse.prototype.getNamespaces = function() {
  return /** @type{?proto.ui.GetControlStreamResponse.NamespaceStates} */ (
    jspb.Message.getWrapperField(this, proto.ui.GetControlStreamResponse.NamespaceStates, 1));
};


/**
 * @param {?proto.ui.GetControlStreamResponse.NamespaceStates|undefined} value
 * @return {!proto.ui.GetControlStreamResponse} returns this
*/
proto.ui.GetControlStreamResponse.prototype.setNamespaces = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.ui.GetControlStreamResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ui.GetControlStreamResponse} returns this
 */
proto.ui.GetControlStreamResponse.prototype.clearNamespaces = function() {
  return this.setNamespaces(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ui.GetControlStreamResponse.prototype.hasNamespaces = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Notification notification = 2;
 * @return {?proto.ui.Notification}
 */
proto.ui.GetControlStreamResponse.prototype.getNotification = function() {
  return /** @type{?proto.ui.Notification} */ (
    jspb.Message.getWrapperField(this, ui_notifications_pb.Notification, 2));
};


/**
 * @param {?proto.ui.Notification|undefined} value
 * @return {!proto.ui.GetControlStreamResponse} returns this
*/
proto.ui.GetControlStreamResponse.prototype.setNotification = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.ui.GetControlStreamResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ui.GetControlStreamResponse} returns this
 */
proto.ui.GetControlStreamResponse.prototype.clearNotification = function() {
  return this.setNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ui.GetControlStreamResponse.prototype.hasNotification = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.ui.EventType = {
  UNKNOWN_EVENT: 0,
  FLOW: 1,
  K8S_NAMESPACE_STATE: 2,
  SERVICE_STATE: 3,
  SERVICE_LINK_STATE: 4,
  FLOWS: 5,
  STATUS: 6
};

/**
 * @enum {number}
 */
proto.ui.IPProtocol = {
  UNKNOWN_IP_PROTOCOL: 0,
  TCP: 1,
  UDP: 2,
  ICMP_V4: 3,
  ICMP_V6: 4
};

/**
 * @enum {number}
 */
proto.ui.StateChange = {
  UNKNOWN_STATE_CHANGE: 0,
  ADDED: 1,
  MODIFIED: 2,
  DELETED: 3,
  EXISTS: 4
};

goog.object.extend(exports, proto.ui);
